import styles from '../styles/Header.module.css'
import { useState, useEffect, useRef } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import arrow from '@/public/arrow.svg'

export default function ScrollMenu() {
  const [isPizzaOpen, setIsPizzaOpen] = useState(false)
  const [isBurgerOpen, setIsBurgerOpen] = useState(false)
  const menuRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsPizzaOpen(false)
        setIsBurgerOpen(false)
      }
    }

    document.body.addEventListener('click', handleClickOutside)

    return () => {
      document.body.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handlePizzaClick = () => {
    setIsPizzaOpen(!isPizzaOpen)
    setIsBurgerOpen(false) // Close burger dropdown
  }

  const handleBurgerClick = () => {
    setIsBurgerOpen(!isBurgerOpen)
    setIsPizzaOpen(false) // Close pizza dropdown
  }

  const options = [
    { id: '1', href: '/pasta', label: '🍝' },
    { id: '2', href: '/sallad', label: '🥗' },
    { id: '3', href: '/kebab', label: '🌯' },
    { id: '4', href: '/more', label: '🍟' },
  ]
  const pizzaOptions = [
    { id: '5', href: '/pizza', label: 'Alla Pizzor' },
    { id: '6', href: '/pizza/standard', label: 'Standard' },
    { id: '7', href: '/pizza/italiensk', label: 'Italiensk' },
    { id: '8', href: '/pizza/mexikansk', label: 'Mexikansk' },
    { id: '9', href: '/pizza/panpizza', label: 'Pan Pizza' },
  ]
  const burgerOptions = [
    { id: '10', href: '/burgare', label: 'Burgare' },
    { id: '11', href: '/burgare/hamburgare', label: 'Hamburgare' },
    { id: '12', href: '/burgare/smashburgare', label: 'Smashburgare' },
  ]

  return (
    <div className={styles.scrollWrapper}>
      <section className={styles.lunchSpan}>
        <div className={styles.linkWrapper} ref={menuRef}>
          <div
            className={styles.listWrapper}
            onClick={handlePizzaClick}
            role='button'
            aria-haspopup='true'
            aria-expanded={isPizzaOpen}
          >
            <Image
              src={arrow}
              alt='arrow icon'
              className={styles.arrowIcon}
              style={{
                rotate: isPizzaOpen ? '-180deg' : '0deg',
                transition: 'all 0.6s',
                cursor: 'pointer',
              }}
            />
            <button role='button' aria-label='Pizza Menu'>
              🍕
            </button>
            <div
              className={styles.subMenu}
              style={{
                display: isPizzaOpen ? 'block' : 'none',
              }}
              role='menu'
            >
              <menu>
                {pizzaOptions.map((option) => (
                  <Link key={option.id} href={option.href} title={option.label}>
                    <li role='menuitem'>{option.label}</li>
                  </Link>
                ))}
              </menu>
            </div>
          </div>
          <div
            className={styles.listWrapper}
            onClick={handleBurgerClick}
            role='button'
            aria-haspopup='true'
            aria-expanded={isBurgerOpen}
          >
            <Image
              src={arrow}
              alt='arrow icon'
              className={styles.arrowIcon}
              style={{
                rotate: isBurgerOpen ? '-180deg' : '0deg',
                transition: 'all 0.4s',
                cursor: 'pointer',
              }}
            />
            <button role='button' aria-label='Burger Menu'>
              🍔
            </button>
            <div
              className={styles.subMenu}
              style={{ display: isBurgerOpen ? 'block' : 'none' }}
              role='menu'
            >
              <menu>
                {burgerOptions.map((option) => (
                  <Link key={option.id} href={option.href} title={option.label}>
                    <li role='menuitem'>{option.label}</li>
                  </Link>
                ))}
              </menu>
            </div>
          </div>
          <menu className={styles.dropdownContent} role='menu'>
            {options.map((option) => (
              <li key={option.id} role='menuitem'>
                <Link href={option.href} title={option.label}>
                  <button role='button'>{option.label}</button>
                </Link>
              </li>
            ))}
          </menu>
        </div>
      </section>
    </div>
  )
}
